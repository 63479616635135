<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return">
          <el-button type="text" size="small" @click="config.isDetailDisplay = false"
            >返回</el-button
          >
        </p>
        <el-button type="primary" size="small" @click="add" v-right-code="'Rulemember:Init'">新增</el-button>
        <el-button type="primary" size="small" @click="save" v-right-code="'Rulemember:Init'">保存</el-button
        >
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
      <el-tab-pane name="tabbasic" label="基本信息">
        <div class="form-list">
          <el-form
            :model="dataSource"
            ref="_ruleForm"
            :rules="projectCheckRule"
          >
            <el-form-item>
              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>规则名称：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="RuleName">
                  <el-input
                    type="text" maxlength="50"
                    v-model="dataSource.RuleName"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>货主名称：</el-col>
              <el-col :span="8">
                  <ChooseCustomer v-model="dataSource.CustomerCode" :customerName.sync="dataSource.CustomerName" :onChange="customerChange"></ChooseCustomer>
              </el-col>

              
            </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span><span>状态：</span></el-col
              >
              <el-col :span="8">
                <el-form-item>
                  <el-radio v-model="dataSource.RuleOrderAssignStatus" label="100"
                    >启用</el-radio
                  >
                  <el-radio v-model="dataSource.RuleOrderAssignStatus" label="200"
                    >停用</el-radio
                  >
                </el-form-item>
              </el-col>
                
              <el-col :span="4" class="form-title">
                <span style="color: red">*</span>单据类型：</el-col>
              <el-col :span="8">
                <el-form-item prop="RuleBusinessTypeCode">
                  <el-select
                    v-model="dataSource.RuleBusinessTypeCode"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in billTypeList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                      @change="updateBillType()"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>生效日期：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="EffectiveDate">
                  <el-date-picker
                    v-model.trim="dataSource.EffectiveDate"
                    placeholder="选择日期"
                    type="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>失效日期：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="ExpiredDate">
                  <el-date-picker
                    v-model.trim="dataSource.ExpiredDate"
                    placeholder="选择日期"
                    type="date"
                     value-format="yyyy-MM-dd HH:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
             <el-form-item>
               <el-col :span="4" class="form-title"><span style="color: red">*</span>优先级：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="Priority">
                                     <el-input type="text" maxlength="5" v-model.number="dataSource.Priority"></el-input>
                                </el-form-item>
                            </el-col>
             </el-form-item>
            <el-form-item>
              <el-col :span="4" class="form-title">规则描述：</el-col>
              <el-col :span="20">
                <el-form-item prop="Remark">
                  <el-input
                    type="textarea"
                    maxlength="200"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="dataSource.Remark"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
           
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

 <el-tabs type="border-card" v-model="activeNameConfig">
      <el-tab-pane label="仓库列表" name="first">
        <el-row>
          <el-col :span="24">
          <el-table :data="dataSource.Params">
               <el-table-column
                prop="MatchableStatus"
                label="可匹配状态"
                align="center"
              >
                <template slot-scope="scope">
                   <el-switch v-model="scope.row.MatchableStatus"></el-switch>
                </template>
              </el-table-column>

              <el-table-column
                prop="DeliveryWay"
                label="承运方式"
                align="center"
              >
                <template slot-scope="scope">
                   <el-select
                    v-model="scope.row.DeliveryWay"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in transportTypelist"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

               <el-table-column
                prop="MemberName"
                label="承运商"
                align="center"
              >
                <template slot-scope="scope">
                   <el-input v-model="scope.row.MemberName" @click.native="showcarrier(scope.row.FilterID)" suffix-icon="el-icon-search"></el-input>
                </template>
              </el-table-column>

               <el-table-column
                prop="MemberProductName"
                label="产品"
                align="center"
              >
                <template slot-scope="scope">
                   <el-input v-model="scope.row.MemberProductName" @click.native="carrierProductAlert(scope.row.FilterID)" suffix-icon="el-icon-search"></el-input>
                </template>
              </el-table-column>

               <el-table-column
                prop="Priority"
                label="发货优先级"
                align="center"
              >
                <template slot-scope="scope">
                   <el-input-number v-model="scope.row.Priority"></el-input-number>
                </template>
              </el-table-column>

              <el-table-column label="操作" :min-width="80">
                <template slot-scope="scope">
                  <el-button
                    @click="deleteWarehouseData(scope.row)"
                    size="small"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <div class="h-handle-button" center>
          <center>
            <el-button type="text" size="small" @click="addWarehouseetail()"
              >新增</el-button
            >
            <!--<el-button type="success" @click="test()">测试</el-button>-->
          </center>
        </div>
      </el-tab-pane>
     </el-tabs>

    <el-tabs type="border-card" v-model="activeNameConfig">
      <el-tab-pane label="满足条件" name="first">
        <el-row>
          <el-radio-group v-model="dataSource.ParamOpeatorType">
                        <el-radio-button :label="1">且</el-radio-button>
                        <el-radio-button :label="2">或</el-radio-button>
                    </el-radio-group>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table :data="dataSource.Detail">
              <el-table-column prop="sort" label="序号" width="55">
              </el-table-column>
              <el-table-column
                prop="ParamFieldLabel"
                label="参数名称"
                align="center"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.ParamFieldName"
                    filterable
                    placeholder="请选择"
                    v-if="scope.row.isEdit"
                    @change="updateParamField(scope.row)"
                  >
                    <el-option
                      v-for="item in dataSource.ruledtlofmetadatalist"
                      :key="item.ParamFieldName"
                      :label="item.ParamFieldLabel"
                      :value="item.ParamFieldName"
                    >
                    </el-option>
                  </el-select>

                  <span v-else v-text="scope.row.ParamFieldLabel"></span>
                </template>
              </el-table-column>

              <el-table-column prop="OperatorTag" label="操作符" align="center">
                <template slot-scope="scope">
                  <el-select
                    v-if="scope.row.isEdit"
                    v-model="scope.row.ParamOperator"
                    filterable
                    placeholder="请选择"
                    @change="updateOperator(scope.row)"
                  >
                    <el-option
                      v-for="item in scope.row.OperatorListSelectAll"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>

                  <span v-else v-text="scope.row.ParamOperatorTag"></span>
                </template>
              </el-table-column>

              <el-table-column
                prop="ParamValue"
                width="300"
                label="参数值"
                align="center"
              >
                <template slot-scope="scope">
                  <el-date-picker
                    v-if="
                      scope.row.isEdit &&
                      scope.row.ruleDtlOfMetaData != null &&
                      scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                        ParamFieldControlCode.Date &&
                      scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                        ParamFieldValueTypeCode.DateTimeFormat
                    "
                    v-model="scope.row.ParamValue"
                    type="datetime"
                    placeholder="选择时间"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss" @change="changeParamValue(scope.row)"
                  ></el-date-picker>

                  <el-date-picker
                    v-else-if="
                      scope.row.isEdit &&
                      scope.row.ruleDtlOfMetaData != null &&
                      scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                        ParamFieldControlCode.Date &&
                      scope.row.ruleDtlOfMetaData.ParamFieldValueType ==
                        ParamFieldValueTypeCode.DateFormat
                    "
                    v-model="scope.row.ParamValue"
                    type="date"
                    placeholder="选择日期" @change="changeParamValue(scope.row)"
                  ></el-date-picker>

                  <span
                    v-else-if="
                      scope.row.isEdit &&
                      scope.row.ruleDtlOfMetaData != null &&
                      scope.row.ruleDtlOfMetaData.ParamFieldType ==
                        ParamFieldTypeCode.BooleanType &&
                      scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                        ParamFieldControlCode.SingleChoice
                    "
                  >
                  <el-radio-group v-model="scope.row.ParamValue">
                    <el-radio label="true" @change="changeParamValue(scope.row)"
                      >是</el-radio
                    >
                    <el-radio label="false" @change="changeParamValue(scope.row)"
                      >否</el-radio
                    >
                  </el-radio-group>
                  </span>

                  <el-select
                    v-else-if="
                      scope.row.isEdit &&
                      scope.row.ruleDtlOfMetaData != null &&
                      scope.row.ruleDtlOfMetaData.ParamFieldType ==
                        ParamFieldTypeCode.IntType &&
                      scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                        ParamFieldControlCode.Select
                    "
                    v-model="scope.row.ParamValue"
                    @change="changeParamValue(scope.row)"
                  >
                    <el-option
                      v-for="item in scope.row.ruleDtlOfMetaData.DictionaryList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                    v-else-if="
                      scope.row.isEdit &&
                      scope.row.ruleDtlOfMetaData != null &&
                      scope.row.ruleDtlOfMetaData.ParamFieldType ==
                        ParamFieldTypeCode.VarcharType &&
                      scope.row.ruleDtlOfMetaData.ParamFieldControl ==
                        ParamFieldControlCode.Select
                    "
                    @change="changeParamValue(scope.row)"
                    v-model="scope.row.ParamValue"
                  >
                    <el-option
                      v-for="item in scope.row.ruleDtlOfMetaData.DictionaryList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>

                  <el-input
                    v-else-if="scope.row.isEdit"
                    v-model="scope.row.ParamValue"
                    @change="changeParamValue(scope.row)"
                  ></el-input>
 
                  <span
                    v-if="!scope.row.isEdit"
                    v-text="scope.row.ParamValueTag"
                  ></span>
                </template>
              </el-table-column>

              <el-table-column label="操作" :min-width="80">
                <template slot-scope="scope">
                  <el-button
                    @click="editData(scope.row)"
                    size="small"
                    type="text"
                    v-if="!scope.row.isEdit"
                    >编辑</el-button
                  >
                  <el-button
                    @click="endEditData(scope.row)"
                    size="small"
                    type="text"
                    v-if="scope.row.isEdit"
                    >完成</el-button
                  >
                  <el-button
                    @click="deleteData(scope.row)"
                    size="small"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <div class="h-handle-button" center>
          <center>
            <el-button type="text" size="small" @click="addruledetail()"
              >新增</el-button
            >
            <!--<el-button type="success" @click="test()">测试</el-button>-->
          </center>
        </div>
      </el-tab-pane>
    </el-tabs>
    <DialogEx :options="dynamicsdataOptions" title="请选择" @onOk="onCarrierOk">
            <DynamicsData ref="dynamicsdata" :config="dynamicsdataConfig" @onSelectedRow="onCarrierSelectedRow"></DynamicsData>
    </DialogEx>
     <DialogEx :options="carrierOptions" title="选择承运商" @onOk="onCarrierOk">
            <Carrier ref="carrier" :config="carrierConfig" @onSelectedRow="onCarrierSelectedRow"></Carrier>
        </DialogEx>

      <DialogEx :options="carrierProductOptions" title="选择承运商产品" @onOk="onCarrierProductOk">
			<CarrierProduct ref="carrierProduct" :config="carrierProductConfig" @onSelectedRow="onCarrierProductRow">
			</CarrierProduct>
		</DialogEx>

  </div>
</template>
<script>
export default {
  data() {
    var _this = this;
    var checkRuleName = function (rule, value, callback) {
      if (!value) return callback(new Error("请输入规则名称"));
      if(_this.Utils.isEmpty(value)) return callback(new Error("请输入规则名称"));
      callback();
    };
    var checkProjectCode = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("请选择项目"));
      callback();
    };

     var checkEffectiveDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("生效日期不能为空"));
      callback();
    };

     var checkExpiredDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("失效日期不能为空"));
      callback();
    };

     var checkPriority= function (rule, value, callback) {
        if (value == null || value.length == 0)
            return callback(new Error("优先级不能为空"));
        if(value>32767)
            return callback(new Error("优先级数值过大"));
        callback();
        };

    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      activeName: "tabbasic",
      activeNameConfig: "first",
      multipleSelection: [],
      isDisabled: false,
      projectCheckRule: {
        RuleName: [{ validator: checkRuleName, trigger: "blur" }],
        ProjectCode: [{ validator: checkProjectCode, trigger: "blur" }],
        EffectiveDate: [{ validator: checkEffectiveDate, trigger: "blur" }],
        ExpiredDate:[{ validator: checkExpiredDate, trigger: "blur" }],
        Priority:[{ validator: checkPriority, trigger: "blur" }],
      },
      addruledetailOptions: {
        visible: false,
        size: "small",
        width: "40%",
      },
      currentEnterpriseSelectedRow: {},
      //选择仓库
      warehouseConfig: {
        isDetailDisplay: true,
      },
      warehouseOptions: {
        visible: false,
        size: "small",
      },
      currentWarehouseSelectedRow: {},
      queryParam: {
        PageIndex: 1,
        PageSize: 10,
      },
      configdataSource: {
        ColDefs: {},
        Result: [],
        TotalCount: 0,
      },
      inputVisible: false,
      inputValue: "",
      projectList: [],
      gainTypeList: [],
      billTypeList: [{label:'物流指令',value:3}],
      billBodyList: [],
      paramFieldTypeList: [],
      paramFieldControlList: [],
      operatorList: [],
      DynamicsDataTitle:"选择列",
      paramFieldValueTypeList: [],
      transportTypelist:[],
      boolList: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      ParamFieldControlCode: {
        POPUP: 100,
        Select: 101,
        InputText: 102,
        MultilineInput: 103,
        Date: 104,
        SingleChoice: 105,
        CheckChoice: 106,
      },
       dynamicsdataOptions: {
        visible: false,
        size: 'small'
      },
      dynamicsdataConfig: {
        TableName:"",
        ParamFieldName:""
      },
      ParamFieldValueTypeCode: {
        SQL: 100,
        Dictionary: 101,
        Enumeration: 102,
        Text: 103,
        DateFormat: 104,
        DateTimeFormat: 105,
        FixedOptions: 106,
      },
      ParamFieldTypeCode: {
        IntType: 1,
        VarcharType: 2,
        BooleanType: 3,
        DateType: 4,
        DateTimeType: 5,
        NumericType: 6,
      },
      carrierOptions: {
                    visible: false,
                    size: 'small'
                },
  carrierConfig: {
                    isDetailDisplay: true,
                    IsSuccess:true,
                },
        carrierProductOptions: {
					visible: false,
					size: 'small'
				},
         carrierProductConfig: {
					isDetailDisplay: true,
					MemberId: 0,
					MemberCode: ''
				},
        currentCarrierSelectedRow:[],
    };
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {},
  },
  computed: {},
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        this.isDisabled =
          this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
      },
      deep: true,
    },
  },

  mounted() {
    this.Event.$on("clearEditFormofOrderAudit", () => this.resetForm);
    this.initBillBodyList();
    this.initBillTypeList();
    this.initParamFieldTypeList();
    this.initParamFieldControlList();
    this.initOperatorList();
    this.initParamFieldValueTypeList();
    this.iniTransportTypelist();
    this.initDetail();
    this.updateBillType();
  },

  methods: {
    //选择客户
    onEnterpriseSelectedRow: function (row) {
      this.currentEnterpriseSelectedRow = row;
    },
    initBillTypeList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getbilltypelist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.billTypeList=data.Result;
          }
        }
      );
    },
    iniTransportTypelist:function()
    {
      var _this = this;
      _this.$ajax.send(
        "omsapi/rulewarehouse/getTransportTypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.transportTypelist = data.Result;
          }
        }
      );
    },
    initGainTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getgaintypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.gainTypeList = data.Result;
          }
        }
      );
    },
    initBillBodyList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getbillbodylist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.billBodyList = data.Result;
          }
        }
      );
    },
    initDetail: function () {
      var _this = this;

      if (
        _this.dataSource.Detail != null &&
        _this.dataSource.Detail.length > 0
      ) {
        var index = 0;
        for (var i = 0; i < _this.dataSource.Detail.length; i++) {
          index++;
          _this.dataSource.Detail[i].sort = index;
          _this.dataSource.Detail[i].isEdit = false;
        }
      }
    },
    test:function()
    {
       var _this = this;
      _this.$ajax.send(
        "omsapi/rulemember/test",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            var j=0;
            _this.dynamicsdataConfig.TableName = "orderinfo";
            _this.dynamicsdataConfig.ParamFieldName="test200";
            _this.dynamicsdataOptions.visible = true;
            _this.DynamicsDataTitle="请选择";
          }
        }
      );
    },
    initParamFieldTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldtypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldTypeList = data.Result;
          }
        }
      );
    },
    initParamFieldControlList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldcontrollist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldControlList = data.Result;
          }
        }
      );
    },
    initOperatorList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getoperatorlist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.operatorList = data.Result;
          }
        }
      );
    },
    changeParamValue:function(obj)
    {
      var ParamFieldControl=obj.ruleDtlOfMetaData.ParamFieldControl; 
      var ParamFieldType= obj.ruleDtlOfMetaData.ParamFieldType;
                         
      if(ParamFieldControl==this.ParamFieldControlCode.InputText)
      {
        obj.ParamValueTag=obj.ParamValue;
      }else if(ParamFieldControl==this.ParamFieldControlCode.SingleChoice && ParamFieldType==this.ParamFieldTypeCode.BooleanType)
      {
        if(obj.ParamValue=="true")
        {
          obj.ParamValueTag="是";
        }else{
          obj.ParamValueTag="否";
        }
      }else if( obj.ruleDtlOfMetaData.ParamFieldControl ==
                        this.ParamFieldControlCode.Date &&   
obj.ruleDtlOfMetaData.ParamFieldValueType ==
                        this.ParamFieldValueTypeCode.DateTimeFormat)
      {
        obj.ParamValueTag=obj.ParamValue;
      }else if(obj.ruleDtlOfMetaData.ParamFieldControl ==
                        this.ParamFieldControlCode.Date &&
                      obj.ruleDtlOfMetaData.ParamFieldValueType ==
                        this.ParamFieldValueTypeCode.DateFormat)
      {
        obj.ParamValueTag=this.Utils.formatDate(obj.ParamValue);
      }else if((obj.ruleDtlOfMetaData.ParamFieldType ==
                        this.ParamFieldTypeCode.IntType &&
                      obj.ruleDtlOfMetaData.ParamFieldControl ==
                        this.ParamFieldControlCode.Select) || 
                        (
                          obj.ruleDtlOfMetaData.ParamFieldType ==
                        this.ParamFieldTypeCode.VarcharType &&
                      obj.ruleDtlOfMetaData.ParamFieldControl ==
                        this.ParamFieldControlCode.Select
                        ))
      {
          if(obj.ruleDtlOfMetaData.DictionaryList!=null && obj.ruleDtlOfMetaData.DictionaryList.length>0)
          {
            var list = obj.ruleDtlOfMetaData.DictionaryList;

            for (var i = 0; i < list.length; i++) {
              if (obj.ParamValue == list[i].Value) {
                obj.ParamValueTag = list[i].Label;
                break;
              }
            }
          }
      }
    },
    initParamFieldValueTypeList: function () {
      var _this = this;
      _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldvaluetypelist",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            _this.paramFieldValueTypeList = data.Result;
          }
        }
      );
    },
    updateParamFieldType: function (obj) {
      var list = this.paramFieldTypeList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldType == list[i].Value) {
          obj.ParamFieldTypeTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldControl: function (obj) {
      var list = this.paramFieldControlList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldControl == list[i].Value) {
          obj.ParamFieldControlTag = list[i].Label;
          break;
        }
      }
    },
    updateParamField: function (obj) {
      var list = this.dataSource.ruledtlofmetadatalist;

      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldName == list[i].ParamFieldName) {
          obj.ParamFieldLabel = list[i].ParamFieldLabel;
          obj.OperatorListSelectAll = list[i].OperatorListSelectAll;
          obj.ruleDtlOfMetaData = list[i];
          obj.TableName= list[i].TableName;
          obj.RuleDtlId=list[i].RuleDtlId;
          break;
        }
      }

      if (
        obj.isEdit &&
        obj.ruleDtlOfMetaData != null &&
        obj.ruleDtlOfMetaData.ParamFieldType ==
          this.ParamFieldTypeCode.BooleanType &&
        obj.ruleDtlOfMetaData.ParamFieldControl ==
          this.ParamFieldControlCode.SingleChoice
      ) {
        if (obj.ParamValue == null || obj.ParamValue.length == 0) {
          var temp=this.Utils.cloneObj(obj);
          temp.ParamValue = "true";
          temp.ParamValueTag="是";
          obj=temp;
        }else if(obj=="true")
        {
          obj.ParamValueTag="是";
        }else{
          obj.ParamValueTag="否";
        }
      }
    },
    updateBillType: function () {
      var _this = this;
      var list = this.billTypeList;
      var BillType= this.dataSource.RuleBusinessTypeCode;

      if(BillType==null || BillType.length==0)
      {
        return;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: BillType },
        (data) => {
          if (data.IsSuccess) {
            _this.dataSource.ruledtlofmetadatalist = data.Result;
          }
        }
      );
    },
    updateOperator: function (obj) {
      var list = obj.OperatorListSelectAll;
      var strOperatorTag = "";
      var strOperator = "";

      if (
        obj.OperatorListSelectAll == null ||
        obj.OperatorListSelectAll.length == 0
      ) {
        obj.OperatorTag = "";
        return;
      }

      for (var i = 0; i < list.length; i++) {
        if (obj.ParamOperator == list[i].Value) {
          strOperatorTag += list[i].Label + ";";
          strOperator += list[i].Value + ";";
          break;
        }
      }
      obj.ParamOperatorTag = strOperatorTag;
    },
    updateParamFieldValueType: function (obj) {
      var list = this.paramFieldValueTypeList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldValueType == list[i].Value) {
          obj.ParamFieldValueTypeTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldPage: function (obj) {
      var list = this.boolList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldPage == list[i].Value) {
          obj.ParamFieldPageTag = list[i].Label;
          break;
        }
      }
    },
    updateParamFieldStatus: function (obj) {
      var list = this.boolList;
      for (var i = 0; i < list.length; i++) {
        if (obj.ParamFieldStatus == list[i].Value) {
          obj.ParamFieldStatusTag = list[i].Label;
          break;
        }
      }
    },
    add: function () {
      this.Event.$emit("onAddRuleOfOrderAudit");
    },
    save: function () {
      var _this = this;
      _this.$refs["_ruleForm"].validate((valid) => {
        _this.isPassValidate = valid;
        if (valid) {
          if(_this.dataSource.CustomerCode==null || _this.dataSource.CustomerCode.length==0)
                        {
                            _this.Utils.messageBox("未选择货主名称", "error");
                            return ;
                        }
          if (
            _this.dataSource.Detail != null &&
            _this.dataSource.Detail.length > 0
          ) {
            //配置对象验证
            var detail = _this.dataSource.Detail;
            for (var i = 0; i < detail.length; i++) {
              if (
                detail[i].ParamFieldName == null ||
                detail[i].ParamFieldName.length == 0
              ) {
                _this.Utils.messageBox("参数名称不能为空", "error");
                return;
              }

              if (
                detail[i].ParamOperator == null ||
                detail[i].ParamOperator.length == 0
              ) {
                _this.Utils.messageBox("操作符不能为空", "error");
                return;
              }
            }
          }

          _this.$ajax.send(
            "omsapi/rulemember/save",
            "post",
            _this.dataSource,
            (data) => {
              if (data.IsSuccess) {
                _this.Utils.messageBox(data.OperationDesc, "success");
                _this.$parent.syncDataSource();
                _this.Event.$emit("reloadRuleOfOrderAuditList");
                _this.dataSource.Detail = [];
                _this.dataSource.Params=[];
                _this.$refs["_ruleForm"].resetFields(); //清空表单
                _this.inputValue = "";
              } else {
                _this.Utils.messageBox(data.OperationDesc, "error");
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    addruledetail: function () {
       var _this = this;
      var list = this.billTypeList;
      var BillType= this.dataSource.RuleBusinessTypeCode;

      if(BillType==null || BillType.length==0)
      {
        return;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getruledtlofmetadatalist",
        "post",
        { projectcode: this.dataSource.ProjectCode, billType: BillType },
        (data1) => {
          if (data1.IsSuccess) {
            _this.dataSource.ruledtlofmetadatalist = data1.Result;
              
      if(_this.dataSource.ruledtlofmetadatalist==null || _this.dataSource.ruledtlofmetadatalist.length==0)
      {
        _this.Utils.messageBox("对象配置参数为空", "error");
        return ;
      }

      _this.$ajax.send(
        "omsapi/ruleofmetadata/getemptyruledetail",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
            var newruledetial = data.Result;
            if (
              _this.dataSource.Detail == undefined ||
              _this.dataSource.Detail == null
            ) {
              _this.dataSource.Detail = [];
            }

            
            for (var i = 0; i < _this.dataSource.Detail.length; i++) {
              _this.dataSource.Detail[i].isEdit = false;
            }

            var index = _this.dataSource.Detail.length;
            index++;
            newruledetial.sort = index;
            newruledetial.isEdit = true;
            newruledetial.ruledtlofmetadatalist=_this.Utils.cloneObj(_this.dataSource.ruledtlofmetadatalist);
            _this.dataSource.Detail.push(newruledetial);
          }
        }
      );
          }
        }
      );
    },
    handleClose(tag) {
      this.dataSource.dynamicTags.splice(
        this.dataSource.dynamicTags.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
 carrierProductAlert: function(obj) {
         this.FilterID=obj;
         var model=null;

         for(var i=0;i<this.dataSource.Params.length;i++)
         {
           if(this.dataSource.Params[i].FilterID==obj)
           {
               model=this.dataSource.Params[i];
           }
         }

        
				if (model==null || model.MemberName==null || model.MemberName.length==0) {
					this.Utils.messageBox("请选择承运商");
					return;
				}
				this.carrierProductConfig.MemberCode = model.MemberCode;
				this.carrierProductOptions.visible = true;
			},
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dataSource.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    deleteData: function (obj) {
      this.dataSource.Detail.splice(this.dataSource.Detail.indexOf(obj), 1);
    },
    tab1Click: function (tab, event) {},
    tab2Click: function (tab, event) {},
    editData: function (obj) {
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }
      obj.isEdit = true;
    },
    endEditData: function (obj) {
      for (var i = 0; i < this.dataSource.Detail.length; i++) {
        this.dataSource.Detail[i].isEdit = false;
      }
      this.changeParamValue(obj);
    },
     deleteWarehouseData: function (obj) {
      this.dataSource.Params.splice(this.dataSource.Params.indexOf(obj), 1);
    },
     showcarrier:function(obj)
    {
      this.FilterID=obj;
      //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode;
      this.carrierOptions.visible=true;
    },
     onCarrierSelectedRow: function (row) {
                this.currentCarrierSelectedRow = row;
            },
    addWarehouseetail:function()
    {
      var _this=this;
      if(this.dataSource.Params==null)
      {
        this.dataSource.Params=[];
      }

      _this.$ajax.send(
        "omsapi/rulemember/getemptyruledetail",
        "get",
        {},
        (data) => {
          if (data.IsSuccess) {
                var obj=data.Result;
                obj.Priority=1;
                this.dataSource.Params.push(obj);
          }
        });
    },
    resetForm() {
      !this.isPassValidate && this.$refs["_ruleForm"].resetFields(); //清空表单
      this.dataSource.Detail = [];
      this.dataSource.EnableStatus = "100";
      this.dataSource.TransportOrderStatus = false;
      this.dataSource.PhurchaseOrderStatus = false;
      this.dataSource.SaleOrderStatus = false;
    },
    reloadPageList: function () {
      this.initialize();
    },
    initialize() {
      this.onPageChange(this.queryParam);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("onCheckRow", this.multipleSelection);
    },
     onCarrierOk: function () {
                var _this = this;
                var row = this.currentCarrierSelectedRow;
                if (row instanceof Object && row.MemberCode != null && row.MemberCode != '' && row.MemberCode != undefined) {
                     
                    for(var i=0;i<this.dataSource.Params.length;i++)
                    {
                      if(this.dataSource.Params[i].FilterID==this.FilterID)
                      {
                        this.dataSource.Params[i].MemberId=row.MemberId;
                        this.dataSource.Params[i].MemberName=row.MemberName;
                        this.dataSource.Params[i].MemberCode=row.MemberCode;
                      }
                    }
                }
            },
            onCarrierProductOk: function() {
              
				var row = this.currentCarrierProductSelectRow;
				if (row instanceof Object && row.MemberProductCode != "" && row.MemberProductCode != null) {
            for(var i=0;i<this.dataSource.Params.length;i++)
            {
              if(this.dataSource.Params[i].FilterID==this.FilterID)
              {
                this.dataSource.Params[i].MemberProductID=row.MemberProductID
                 this.dataSource.Params[i].MemberProductName=row.MemberProductName;
              }
            }
				}
			},
       onCarrierProductRow: function(row) {
				this.currentCarrierProductSelectRow = row;
			},
    onPageChange(param) {
      var _this = this;
      this.queryParam = param;
      this.$ajax.query(
        "omsapi/logisticsProject/findList",
        "post",
        this.queryParam,
        (data) => {
          _this.dataSource = data;
        }
      );
    },
    projectChange()
    {
      this.updateBillType();
    }, customerChange(){
                //this.warehouseConfig.CustomerCode=this.dataSource.CustomerCode
                //this.productConfig.CustomerCode=this.dataSource.CustomerCode
            },

  },
 components: {
            "DynamicsData": resolve => { require(['../../../selector/dynamicsdataselector.vue'], resolve) },
             "Carrier": resolve => { require(['../../../selector/carrierselector.vue'], resolve) },
 "CarrierProduct": resolve => {
				require(['../../../selector/CarrierProduct.vue'], resolve)
			},
        },
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>